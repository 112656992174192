@use '@work4all/assets/theme/elevations.scss';

.root {
  min-width: 0;
}

.thumbnail {
  width: 100%;
  aspect-ratio: 1/1;
  background-color: var(--ui01);
  border: 2px solid var(--ui01);
  border-radius: 0.5rem;
  @include elevations.elevation2;
  margin-bottom: 0.25rem;
  overflow: hidden;

  &:hover {
    border-color: var(--brand01);
  }
}

.itemText {
  text-wrap: initial;
  overflow: hidden;
  height: 4.5rem;
}
