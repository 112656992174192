@import '@work4all/assets/theme/spacing.scss';

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
}

.item-wrap {
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-rows: min-content;
  padding-left: $spaceS;
  align-items: center;
  align-content: center;
  cursor: pointer;
  height: 2.5rem;
  padding: 0 $spaceS;
  color: var(--text01);

  > a {
    overflow: hidden;
  }
}

.countIndicatorsContainer {
  display: flex;
  gap: 4px;
}

.indicatorLink {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.overviewEditItem {
  overflow: hidden;
  div,
  span,
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
