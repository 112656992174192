.table-cell {
  padding: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;

  white-space: pre;
  overflow-y: auto;
  user-select: none;
  /* hide scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

:global {
  .inside-row {
    white-space: pre-line;
  }
}

.single-line {
  height: 1.25rem;
  line-height: 1.7rem;
}

.multi-line {
  min-height: 100%;
}
