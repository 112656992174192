.container {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}
