@import '@work4all/assets/theme/mixins.scss';

.root {
  box-sizing: content-box;
  border-bottom: 0.0625rem solid var(--ui04);
  border-right: 0.0625rem solid var(--ui04);
  overflow: hidden scroll;
  height: calc(3.25rem - 2px);
  background: var(--ui01);

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar {
    width: auto;
  }
}

.draggableHeader {
  padding-left: 40px;
}
