@use '@work4all/assets/theme/breakpoints.scss';
@use '@work4all/assets/theme/mixins.scss';
@use '@work4all/assets/theme/typography.scss';
@use '@work4all/assets/theme/elevations.scss';

.wrapper {
  max-width: 600px;
}

.paper {
  background-color: #eeeeee;
}

.root {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.title {
  margin-right: auto;
  color: var(--text02);
}

.workTimeContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .innerWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;

    @media (max-width: breakpoints.$md) {
      width: 100%;
    }
  }

  .statisticsContainer {
    grid-area: widget;
    overflow: hidden;
    @include elevations.elevation2;
    border-radius: 0.5rem;
    height: 100%;
    background: var(--ui01);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
  }

  .timeContainer {
    grid-area: control;
    & > div {
      @include elevations.elevation2;
      border-radius: 0.5rem;
    }
  }
}

.projectTimeContent {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  gap: 1rem;

  padding: 1rem;
  overflow: hidden;

  .widgetContainer {
    grid-area: widget;
    overflow: hidden;
    @include elevations.elevation2;
    border-radius: 0.5rem;
  }

  .widgetContainer {
    background-color: var(--ui01);
    padding: 0rem 1rem;
    width: 100%;
  }

  .timeContainer {
    grid-area: control;
    & > div {
      @include elevations.elevation2;
      border-radius: 0.5rem;
    }
  }
  .projectContainer {
    @include elevations.elevation2;
    border-radius: 0.5rem;
    overflow: hidden;
    grid-area: control;
    width: 600px;
    max-width: 100%;
  }

  &.mobile {
    grid-template-areas: 'control';
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }
}

.expandButton {
  color: var(--text03);
}
.toggleTitle {
  @include typography.caption;
  writing-mode: vertical-lr;
  line-height: 2.5;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--text03);
}

.headerTop {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.header {
  background-color: var(--ui01);
  flex-direction: row;

  @include mixins.mainHeadDropshadow;
  display: flex;
  justify-content: flex-start;
  padding: 0.25rem;
  padding-top: calc(0.25rem + var(--w4a-safe-area-top, 0px));

  .headerTitle {
    width: 100%;
  }

  .goBack,
  .list {
    color: var(--text03);
  }

  .list {
    color: var(--brand01);
  }
}
