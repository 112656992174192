.input {
  width: calc(100% + 0.75rem);
  min-width: 0;
  margin: 0;
  outline: none;
  resize: none;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  &::after {
    border-bottom: none;
  }
  &::before {
    border-bottom: none !important;
  }
  margin: calc(-0.5rem + 1px) calc(-0.6rem) calc(-0.5rem + 1px) -0.6rem;
  margin: -0.4rem;
  border: 0.1rem solid var(--text03);
  z-index: 1000;
  line-height: 1.25rem;
}

.innerInput {
  overflow-y: auto !important;

  border-bottom: none;
  width: 100%;
  border: 0;
  background-color: transparent;
  padding: 0;
  color: var(--text01);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  display: block;
  line-height: 1.25rem;
  height: 1.25rem;

  &:focus,
  &:focus {
    outline: 0;
  }
}

.inputPaddingText {
  /* Don't change it to rem cause we need integer pixels values */
  padding: 4px 6px;
}

.inputPadding {
  /* Don't change it to rem cause we need integer pixels values */
  padding: 4px 6px;
}

.number {
  text-align: right;
}
