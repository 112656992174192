@use '@work4all/assets/theme/typography.scss';

.disabled {
  opacity: 0.5;
}

.table {
  min-width: fit-content;
  height: 100%;
  overflow: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.rightAligned {
  text-align: right;
}

.thead {
  border-top: 1px solid var(--ui04);
}

.tfoot {
  border-top: 1px solid var(--ui04);
  border-left: 1px solid var(--ui04);
  height: fit-content;
}

.thead,
.tfoot {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--text03);
  white-space: nowrap;
  border-width: 1px;
  .th {
    border-top: 1px solid var(--ui03);
    border-bottom: 1px solid var(--ui03);
  }
}

.tbody {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--text01);
  position: relative;
  height: calc(100% - 44px);
  overflow-x: hidden;
  border-left: 1px solid var(--ui04);
  border-right: 1px solid var(--ui04);
}

.tfoot {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--text01);

  .th,
  .td {
    border-top: 1px solid var(--ui03);
    border-bottom: 1px solid var(--ui03);
  }
}

.tr {
  display: flex;

  &:not(:hover, .selected) {
    .checkbox {
      opacity: 0;
    }
  }
}

.tr-border {
  border-top: 1px solid var(--ui04);
  border-bottom: 1px solid var(--ui04);
}

.page-break {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  color: var(--ui05);
  width: fit-content;
}

.th,
.td {
  min-height: 3rem;
  padding: 0.5rem;
  position: relative;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.input {
  min-width: 0;
  position: absolute;
  inset: 0;
  border: 2px solid var(--ui04);
  border-radius: 0.25rem;
  outline: none;
  padding: 0.5rem;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  background: var(--ui02);
}
.addArticleRow {
  height: 2.5rem;
  padding-left: 2.75rem;
}

[role='rowgroup'] [role='row'] .checkbox {
  display: flex;
  opacity: 0;
}

[role='rowgroup'] [role='row']:hover .checkbox,
[role='rowgroup'] [role='row'] .checkbox.checked {
  opacity: 1;
}

.table-cell {
  padding: 1rem 0.125rem;
}

.tableWrapper {
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: row;
}

.outerWrap {
  height: 100%;
  display: flex;
  flex: 1;
  overflow: auto;
}

.positionMaskToggle {
  border-top: 1px solid var(--ui04);
  border-left: 1px solid var(--ui04);
  height: 100%;
}

.canDrop {
  border: 1px dashed var(--brand01);
  background-color: var(--ui08);
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 60%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.parent {
  color: var(--text03);
}

// #region Relations
$marker-size: 27px;
$left-size: 27px;

.relationParent {
  .relationMarker {
    position: absolute;
    height: 100%;
    width: $marker-size;
    // Vertical line
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 38px;
      left: $left-size;
      height: calc(100% - 36px);
      border-left: 2px solid var(--text03);
      z-index: 20;
    }
  }
}
.relationChild {
  .relationMarker {
    position: absolute;
    height: 100%;
    width: $marker-size;
    // Horizontal line
    &::before {
      content: '';
      display: block;
      position: absolute;
      // Fallback to 0.5rem for unsupported browsers
      top: calc(0.5rem);
      top: calc(0.5lh);
      left: $left-size;
      width: 18px;
      border-top: 2px solid var(--text03);
      z-index: 20;
    }

    // Vertical line
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: $left-size;
      height: calc(100% + 1px);
      border-left: 2px solid var(--text03);
      z-index: 20;
    }
  }

  // Last row should have shorter indicator line
  &:not(:has(+ & .relationMarker)) {
    .relationMarker {
      // Vertical line
      &::after {
        height: calc(0.5rem);
        height: calc(0.5lh);
      }
    }
  }
}

// #endregion

.icon {
  position: absolute;
  transform: translate(-7px, -2px);
  color: var(--text03);
  padding: 0;
}

.iconCollapsed {
  transform: rotate(-90deg);
}

.line {
  position: absolute;
  width: 18px;
  transform: translate(30px, calc(-50% - 6px));
  border-bottom: 2px dotted var(--text03);
  height: calc(100% - 1px);
}

.longLine {
  position: absolute;
  border-left: 2px dotted var(--text03);
  top: 0;
  transform: translate(50%, 0);
}

.longLineWrapper {
  z-index: 10;
  position: absolute;
  transform: translate(25px, 30px);
  height: 100%;
}

.selectedRow {
  background-color: var(--ui08);
}

.drag-handler {
  width: 41px;
}

.drag-handler-simple {
  width: 6rem;
}

:global {
  .inside-row {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }
}

.right-oriented {
  justify-content: flex-end;
}

.parent-selected {
  background-color: color-mix(in srgb, var(--brand01), transparent 80%);
}

.noBorder {
  border: unset;
}

.noBorderHorizontal {
  border-top: unset;
  border-bottom: unset;
}

.noBorderVertical {
  border-left: unset;
  border-right: unset;
}

.border {
  border-top: 1px solid var(--ui04);
  border-bottom: 1px solid var(--ui04);
}

.borderTop {
  border-top: 1px solid var(--ui04);
}

.autoScroll {
  overflow-y: auto;
}

.blurValue {
  input {
    &[disabled] {
      cursor: not-allowed;
    }
  }
  textarea {
    &[disabled] {
      cursor: not-allowed;
    }
  }
}

// #region Amount marker
$amount-marker-size: 0px;
.relationParent {
  .amountMarker {
    position: absolute;
    height: 100%;

    // Horizontal line
    &::before {
      content: '';
      display: block;
      position: absolute;
      // Fallback to 0.5rem for unsupported browsers
      top: calc(0px + 0.5rem);
      top: calc(0px + 0.5lh);
      left: calc($amount-marker-size);
      width: 18px;
      border-top: 2px solid var(--text03);
      z-index: 20;
    }

    // Vertical line
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: calc(0px + 0.5rem);
      top: calc(0px + 0.5lh);
      left: $amount-marker-size;
      height: calc(100%);
      border-left: 2px solid var(--text03);
      z-index: 20;
    }
  }
}
.relationChild {
  .amountMarker {
    position: absolute;
    height: 100%;
    // Horizontal line
    &::before {
      content: '';
      display: block;
      position: absolute;
      // Fallback to 0.5rem for unsupported browsers
      top: calc(0px + 0.5rem);
      top: calc(0px + 0.5lh);
      left: calc($amount-marker-size - 16px);
      width: 18px;
      border-top: 2px solid var(--text03);
      z-index: 20;
    }

    // Vertical line
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: -6px;
      left: $amount-marker-size;
      height: calc(100% + 1px + 6px + 6px);
      border-left: 2px solid var(--text03);
      z-index: 20;
    }
  }

  // Last row should have shorter indicator line
  &:not(:has(+ & .amountMarker)) {
    .amountMarker {
      // Vertical line
      &::after {
        height: calc(6px + 0.5rem);
        height: calc(6px + 0.5lh);
      }
    }
  }
}

// #endregion

.edit-mode {
  /* hide scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.simpleWrapper {
  overflow-x: hidden;
}

.borderLeft {
  border-left: 1px solid var(--ui04);
}

.classes {
  &-table {
    display: flex;
  }

  &-thead {
    border-left: 1px solid var(--ui04);
  }

  &-tfootHorizontal {
    border-bottom: 1px solid var(--ui04);
    border-left: 1px solid var(--ui04);
    border-right: 1px solid var(--ui04);
  }

  &-tfootVertical {
    border-bottom: 1px solid var(--ui04);
    border-top: unset;
  }

  &-tbody {
    border-left: 1px solid var(--ui04);
    border-right: 1px solid var(--ui04);
  }
  &-tfoot {
    border-bottom: 1px solid var(--ui04);
  }
}
