@use '@work4all/assets/theme/elevations.scss';

@import '@work4all/assets/theme/spacing.scss';

.card {
  font-family: 'Roboto', sans-serif;
  position: relative;
  z-index: 100;
  background-color: var(--ui01);
  border-radius: 0.625rem;
  height: 100%;
  overflow: hidden;
  @include elevations.elevationWidget;

  &__header {
    padding: 0 1rem;
  }

  &.collapsed {
    height: auto;
  }
}

.card-md {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 24px;
  position: relative;
  z-index: 100;
  background-color: var(--ui01);
  border-radius: 0px;

  &__header {
    padding: 0 1rem;
  }

  &.collapsed {
    height: auto;
  }
}

.drop-widget {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: 1rem;

  background-color: var(--ui01);

  &.identical {
    width: 50%;
    left: 50%;
  }

  &-primary {
    color: var(--brand01);
  }

  &-border {
    border-radius: 0.5rem;
    width: 100%;
    height: 100%;
    color: var(--brand01);
    border: 3px dashed var(--brand01);

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }

  &-isOver {
    color: var(--text02);
    border-color: var(--ui05);
  }

  &-disabled {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    span {
      line-height: unset;
    }
  }
}
