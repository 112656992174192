@import '@work4all/assets/theme/spacing.scss';

.card {
  width: 100%;
}

.section-title {
  padding: 0 $spaceS $spaceS $spaceS;
}

.overview-item-wrap {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: min-content;
  gap: 0px 0px;
  grid-template-areas: '. . .';
  width: 100%;

  > div {
    overflow: hidden;
  }
}

@media (max-width: 700px) {
  .overview-item-wrap {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: '. .';
  }
}
